import { Admin, Resource } from 'react-admin';
import { BatchCreate } from './resources/batches';
import { BrandCreate, BrandEdit, BrandList } from './resources/brands';
import { ButtonCreate, ButtonEdit } from './resources/buttons';
import {
  CampaignCreate,
  CampaignEdit,
  CampaignList,
} from './resources/campaign';
import { StepCreate, StepEdit } from './resources/steps';
import { SubtitleCreate, SubtitleEdit } from './resources/subtitles';
import {
  TemplateCreate,
  TemplateEdit,
  TemplateList,
} from './resources/templates';
import { VideoSessionList, VideosessionShow } from './resources/videosessions';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import StepTemplateIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import CampaignIcon from '@mui/icons-material/CameraRoll';
import GroupIcon from '@mui/icons-material/Group';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import StarIcon from '@mui/icons-material/Star';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Dashboard from './components/Dashboard';
import { VideoAdsCreate } from './components/VideoAds/VideoAdCreate';
import { VideoAdEdit } from './components/VideoAds/VideoAdEdit';
import { AccountCreate, AccountEdit, AccountList } from './resources/accounts';
import { AdRequestsList } from './resources/ad-request';
import { CreatorList, CreatorShow } from './resources/creators';
import { EmailCreate, EmailEdit } from './resources/email';
import { IntegrationCreate } from './resources/integrations';
import { Role, UserCreate, UserEdit } from './resources/users';
import { VideoAdsList } from './resources/videoads';
import myTheme from './theme';

// Init FontAwesome icons to system
// needed for dynamic display by id

import { library } from '@fortawesome/fontawesome-svg-core';

// free and pro packages
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { IncentivesList } from './components/Campaign/Incentives/IncentivesList';

library.add(fab, fas, far, fal, fat, fad);
const App = () => (
  <Admin
    theme={myTheme}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    {userRole => (
      <>
        {[Role.ADMIN].includes(userRole) && (
          <Resource
            name="accounts"
            list={AccountList}
            edit={AccountEdit}
            create={AccountCreate}
            icon={GroupIcon}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER, Role.MODERATOR].includes(userRole) && (
          <Resource
            name="brands"
            list={BrandList}
            edit={BrandEdit}
            create={BrandCreate}
            icon={BusinessIcon}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER, Role.MODERATOR].includes(userRole) && (
          <Resource
            name="campaigns"
            list={CampaignList}
            edit={CampaignEdit}
            create={CampaignCreate}
            icon={CampaignIcon}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="steps" edit={StepEdit} create={StepCreate} />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="buttons" edit={ButtonEdit} create={ButtonCreate} />
        )}
        {[Role.ADMIN, Role.CUSTOMER, Role.MODERATOR].includes(userRole) && (
          <Resource
            name="videosessions"
            list={VideoSessionList}
            show={VideosessionShow}
            icon={VideoLibraryIcon}
            options={{ label: 'Video Sessions' }}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER, Role.MODERATOR, Role.EDITOR].includes(
          userRole,
        ) && (
          <Resource
            name="videoads"
            list={userRole !== Role.EDITOR ? VideoAdsList : undefined}
            create={VideoAdsCreate}
            edit={VideoAdEdit}
            icon={OndemandVideoIcon}
            options={{ label: 'Video Ads' }}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="batches" create={BatchCreate} />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource
            name="subtitles"
            create={SubtitleCreate}
            edit={SubtitleEdit}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="email" create={EmailCreate} edit={EmailEdit} />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource
            name="templates"
            create={TemplateCreate}
            edit={TemplateEdit}
            list={TemplateList}
            icon={StepTemplateIcon}
            options={{ label: 'Campaign Templates' }}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER, Role.MODERATOR].includes(userRole) && (
          <Resource
            name="creators"
            list={CreatorList}
            icon={StarIcon}
            show={CreatorShow}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="industries" />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="users" create={UserCreate} edit={UserEdit} />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="integrations" create={IntegrationCreate} />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource
            name="incentives"
            icon={LocalAtmIcon}
            list={IncentivesList}
          />
        )}
        {[Role.ADMIN, Role.CUSTOMER].includes(userRole) && (
          <Resource name="videotakes" />
        )}
        {[Role.ADMIN, Role.EDITOR].includes(userRole) && (
          <Resource
            name="ad-requests"
            options={{ label: 'Ad Requests' }}
            list={AdRequestsList}
          />
        )}
      </>
    )}
  </Admin>
);

export default App;
