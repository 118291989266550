import React from 'react';
import { EditButton, DeleteButton } from 'react-admin';
import { Chip, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import {
  FileUploadOutlined,
  CompareArrows,
  VideoCameraFront,
} from '@mui/icons-material';
import PlatformDisplay from '../../fields/PlatformDisplay';
import { Link, useNavigate } from 'react-router-dom';

const videoAdFooterStyles = makeStyles({
  videoTitleHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
  actionButton: {
    width: '50%',
  },
});

const VideoAdFooter = ({ record }: any) => {
  const styles = videoAdFooterStyles();
  const navigate = useNavigate();
  const date = new Date(record.createdAt);
  return (
    <div>
      <Grid container rowSpacing={1} maxHeight="250px" minHeight="250px">
        <Grid item className={styles.videoTitleHeader}>
          <Tooltip title={record.name}>
            <Typography variant="subtitle1">{record.name}</Typography>
          </Tooltip>
        </Grid>
        <Grid
          container
          spacing={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Grid item>
            <Tooltip title={date.toString()}>
              <Chip
                icon={<FileUploadOutlined />}
                label={date.toLocaleDateString('en-US', {
                  dateStyle: 'long',
                })}
              />
            </Tooltip>
          </Grid>
          {record.platforms.map((platform: string) => (
            <Grid item key={`${platform}_tag`}>
              <Chip
                icon={<PlatformDisplay platform={platform} />}
                label={platform}
              />
            </Grid>
          ))}
          <Grid item>
            <Chip icon={<CompareArrows />} label={record.versions.length - 1} />
          </Grid>
          {record.sessions?.map((videoSession: any) => (
            <Grid item>
              <Link to={`/videosessions/${videoSession.id}/show`}>
                <Chip
                  style={{ width: '120px' }}
                  icon={<VideoCameraFront />}
                  label={videoSession.name}
                  title={videoSession.name}
                  clickable
                />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid container item justifyContent="center">
          <EditButton
            label="More details"
            record={record}
            className={styles.actionButton}
            onClick={event => {
              if (record.taskId) {
                event.preventDefault();
                navigate(`/videoads/${record.id}?taskId=${record.taskId}`);
              }
            }}
          />
          <DeleteButton
            record={record}
            mutationMode="pessimistic"
            className={styles.actionButton}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoAdFooter;
