import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Identifier, RaRecord } from 'ra-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  SimpleShowLayout,
  TextField,
  ReferenceField,
  BooleanField,
  FunctionField,
} from 'react-admin';
import BrowserDisplay from '../../fields/BrowserDisplay';
import CountryDisplay from '../../fields/CountryDisplay';
import OsDisplay from '../../fields/OsDisplay';
import MediaThumbs from './MediaThumbs';
import JsonField from '../../fields/JsonField';
import { ToggleShowHideButton } from './ToggleShowHideButton';
import VideoSessionIncentivesList from './VideoSessionIncentivesList';

const emptyText = 'N/A';

interface StepDisplayProps {
  campaignId?: string;
  sequence: number | null;
  takes: any[];
  stepName?: string;
  sessionId: string;
  disableTakeVetting: boolean;
}

const Footer: React.FC<{ record: any }> = ({ record }) => {
  const formattedDate = useMemo(
    () => format(new Date(record.createdAt), 'dd-MM-yyyy, HH:mm:ss'),
    [record.createdAt],
  );

  const isConfirmed = useMemo(() => record.isConfirmed, [record.isConfirmed]);
  const confirmStyle = useMemo(() => {
    return isConfirmed
      ? { backgroundColor: '#008450', color: 'white' }
      : { backgroundColor: '#B81D13', color: 'white' };
  }, [isConfirmed]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          textAlign: 'center',
          padding: '5px 0',
          fontWeight: 500,
          fontSize: '14px',
          ...confirmStyle,
        }}
      >
        {isConfirmed ? 'Confirmed' : 'Unconfirmed'}
      </div>
      <div style={{ padding: '0 3%' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Created At: </div>
        <div style={{ fontSize: '12px' }}>{formattedDate}</div>
      </div>
    </div>
  );
};

export const StepDisplay: React.FC<StepDisplayProps> = ({
  sequence,
  takes,
  campaignId,
  stepName,
  disableTakeVetting,
}) => {
  const [selectedTakeId, setSelectedTakeId] = useState<string | undefined>(
    undefined,
  );
  const selectedVideoRef = useRef<HTMLVideoElement | null>(null);
  const [customParamsExpanded, setCustomParamsExpanded] = useState(false);
  const [videoSessionExpanded, setVideoSessionExpanded] = useState(false);
  const [isTakeHidden, setIsTakeHidden] = useState(
    takes?.find(take => take.id === selectedTakeId)?.isHidden,
  );

  useEffect(() => {
    if (selectedTakeId) {
      setIsTakeHidden(takes.find(take => take.id === selectedTakeId)?.isHidden);
    }
  }, [selectedTakeId, takes]);

  return (
    <SimpleShowLayout
      sx={{
        borderBottom: '2px solid gray',
        marginBottom: '2%',
        paddingBottom: '3%',
      }}
    >
      <div>
        Step{sequence ? ` ${sequence}` : ''}: {stepName}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '45%' }}>
          <MediaThumbs
            data={takes}
            Footer={Footer}
            setSelectedTakeId={setSelectedTakeId}
            selectedVideoRef={selectedVideoRef}
            disableTakeVetting={disableTakeVetting}
          />
        </div>
        <div
          style={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Accordion
            expanded={videoSessionExpanded}
            style={{ marginBottom: '2%' }}
          >
            <AccordionSummary
              onClick={() => setVideoSessionExpanded(prev => !prev)}
            >
              <Grid
                container
                spacing={1}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Grid item>
                  <Typography>Session Details</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDown
                    style={
                      videoSessionExpanded
                        ? {
                            transform: 'rotate(180deg)',
                            transition: 'transform 150ms ease',
                          }
                        : {
                            transform: 'rotate(360deg)',
                            transition: 'transform 150ms ease',
                          }
                    }
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex' }}>
                <Grid item xs={3}>
                  <SimpleShowLayout>
                    <ReferenceField
                      source="creatorId"
                      reference="creators"
                      link="show"
                      label="Creator number"
                      emptyText={emptyText}
                    >
                      <TextField source="id" />
                    </ReferenceField>
                    <TextField
                      source="creator.email"
                      label="Creator Email"
                      emptyText={emptyText}
                    />
                    <TextField
                      source="creator.fullname"
                      label="Creator Fullname"
                      emptyText={emptyText}
                    />
                    <TextField
                      source="formChoice"
                      label="Additional question"
                      emptyText={emptyText}
                    />
                    <TextField
                      source="creator.submissionCount"
                      label="Brand submissions"
                      emptyText={emptyText}
                    />
                  </SimpleShowLayout>
                </Grid>
                <Grid item xs={3}>
                  <SimpleShowLayout>
                    <FunctionField
                      label="Video Session Id"
                      render={(record: any) => (
                        <span>
                          {record?.id || ''}{' '}
                          {record.retakeOfId ? '(retake)' : ''}
                        </span>
                      )}
                    />
                    <ReferenceField
                      source="campaign.brand.id"
                      reference="brands"
                      emptyText={emptyText}
                      label="Brand"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      source="campaignId"
                      reference="campaigns"
                      emptyText={emptyText}
                    >
                      <TextField source="title" />
                    </ReferenceField>

                    <BooleanField source="consentGiven" label="Consent" />
                  </SimpleShowLayout>
                </Grid>
                <Grid item xs={3}>
                  <SimpleShowLayout>
                    <FunctionField
                      label="OS | Browser | Country"
                      render={(record: RaRecord | undefined): JSX.Element => (
                        <Grid container>
                          <Grid item xs={4}>
                            <OsDisplay osName={record?.userOSName} />
                          </Grid>
                          <Grid item xs={4}>
                            <BrowserDisplay
                              browserName={record?.userClientName}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CountryDisplay
                              userCountryCode={record?.userCountryCode}
                              includeLabel
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                    <FunctionField
                      label="Original Session"
                      render={(record: RaRecord | undefined) =>
                        record?.retakeOfId ? (
                          <a
                            href={`${window.location.origin}/#/videosessions/${record.retakeOfId}/show`}
                            style={{ textDecorationLine: 'none' }}
                          >
                            Session {record?.retakeOfId || ''}{' '}
                          </a>
                        ) : (
                          <Typography variant="body2">N/A</Typography>
                        )
                      }
                    />
                    <FunctionField
                      label="Video Ads"
                      render={(record: RaRecord | undefined) =>
                        record?.videoAds?.map((videoAd: any) => (
                          <div style={{ marginBottom: '5px' }}>
                            <ReferenceField
                              source="id"
                              record={videoAd}
                              reference="videoads"
                              link={'show'}
                            >
                              <TextField
                                source="name"
                                style={{
                                  width: 'fit-content',
                                }}
                              />
                            </ReferenceField>
                          </div>
                        ))
                      }
                    />
                    <FunctionField
                      label="Incentives"
                      render={(record: RaRecord | undefined) => {
                        if (!record) {
                          return <></>;
                        }
                        return (
                          <VideoSessionIncentivesList
                            incentives={record?.videoScenes
                              ?.flatMap((scene: any) => scene?.incentives)
                              .filter(Boolean)}
                          />
                        );
                      }}
                    />
                  </SimpleShowLayout>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={customParamsExpanded}
            style={{ marginBottom: '2%' }}
          >
            <AccordionSummary
              onClick={() => setCustomParamsExpanded(prev => !prev)}
            >
              <Grid
                container
                spacing={1}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Grid item>
                  <Typography>Custom Params</Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowDown
                    style={
                      customParamsExpanded
                        ? {
                            transform: 'rotate(180deg)',
                            transition: 'transform 150ms ease',
                          }
                        : {
                            transform: 'rotate(360deg)',
                            transition: 'transform 150ms ease',
                          }
                    }
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: 'flex' }}>
                <Grid item>
                  <SimpleShowLayout>
                    <JsonField
                      source="customParams"
                      label="Custom Params"
                      emptyText={emptyText}
                    />
                  </SimpleShowLayout>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectedTakeId && (
              <ToggleShowHideButton
                isHidden={isTakeHidden}
                id={selectedTakeId as Identifier}
                showLabel="Show take in Dashboard"
                hideLabel="Hide take in Dashboard"
                resource="videotakes"
                entityName="Video take"
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '70vh',
            }}
          ></div>
        </div>
      </div>
    </SimpleShowLayout>
  );
};
