import React, { useRef } from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  Edit,
  FormDataConsumer,
  Button,
  useRecordContext,
  BooleanInput,
  TopToolbar,
  ImageInput,
  NumberInput,
  Labeled,
  TextField,
  FormTab,
  TabbedForm,
  useRefresh,
} from 'react-admin';
import { EmailTypeChoices, SupportedLocalesChoices } from '../consts';
import {
  CastVariablesList,
  VarsLegendItems,
} from '../components/EmailTemplates/CastVariables';
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AddPhotoAlternate,
  Info,
  KeyboardArrowDown,
  Save,
} from '@mui/icons-material';
import {
  downloadBlob,
  EmptyTextualFieldProps,
  useQuery,
} from '../utils/general';
import { copyEmail, exportEmail, uploadFile } from '../providers/dataProvider';
import makeStyles from '@mui/styles/makeStyles';
import Download from '@mui/icons-material/Download';
import { gifUrlBuilder, GIF_UPLOADS_FOLDER, upload } from '../services/media';
import { useFormContext, useWatch } from 'react-hook-form';
import CreateEmailFromDialog from '../components/Emails/CreateEmailFromDialog';
import {
  getMissingVariablesByType,
  isMissingVariablesByEmailType,
} from '../utils/emails';
import { stickyToolbar } from './toolbar';

export const emailVariables = [
  { id: 'c_email_title', title: 'Email Title' },
  { id: 'c_email_text', title: 'Email Text' },
  { id: 'c_email_sealer', title: 'Email Sealer' },
  { id: 'c_email_CTA', title: 'Email CTA' },
  { id: 'c_email_gif', title: 'Email Gif URL' },
];

const IsDefaultLabel: React.FC = () => (
  <div>
    <span>Is Default</span>
    <Tooltip title="Default templates will populate to new brands">
      <Info />
    </Tooltip>
  </div>
);

export const GeneralInputs = (isEdit: boolean, isTemplate: boolean) => {
  const type = useWatch({ name: 'type' });
  return [
    ...(isTemplate
      ? [
          <TextInput source="id" disabled key="id" />,
          <Grid container direction="row">
            <BooleanInput
              key="isDefault"
              source="isDefault"
              label=""
              isRequired
              validate={[required()]}
              {...(!isEdit ? {} : { defaultValue: false })}
            />
            <IsDefaultLabel />
          </Grid>,
        ]
      : [
          <ReferenceInput
            source="campaignTemplateId"
            reference="brands"
            key="campaignTemplateIdRef"
          >
            <SelectInput
              optionText="name"
              disabled
              {...EmptyTextualFieldProps}
            />
          </ReferenceInput>,
          <ReferenceInput
            source="campaignId"
            reference="campaigns"
            key="campaignIdRef"
          >
            <SelectInput
              optionText="title"
              disabled
              {...EmptyTextualFieldProps}
            />
          </ReferenceInput>,
          <TextInput
            key="replyTo"
            source="replyTo"
            label="Reply To (Override)"
            disabled={type !== 'CAMPAIGN_RETAKE'}
            {...EmptyTextualFieldProps}
          />,
        ]),
    <TextInput
      key="name"
      source="name"
      label="Name"
      isRequired
      validate={[required()]}
    />,
    <SelectInput
      key="type"
      source="type"
      choices={EmailTypeChoices}
      isRequired
      validate={[required()]}
    />,
    <TextInput
      key="subject"
      source="subject"
      label="Subject line"
      isRequired
      validate={[required()]}
    />,
  ];
};

const commonStyles = makeStyles({
  gifText: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  gifDialog: {
    '& .MuiPaper-root': {
      // overflow: 'initial',
      width: '100%',
      height: '80%',
    },
  },
  removeButton: {
    '& .RaFileInputPreview-removeButton': {
      zIndex: 9999,
    },
  },
  inputBlock: {
    '& .ra-input': {
      display: 'block',
    },
  },
});

const uploadVideo = async (file?: File, url?: string) => {
  const fileToUpload =
    file || new File([await (await fetch(url || '')).blob()], 'temp');

  const { secure_url, public_id } = await upload(
    fileToUpload,
    GIF_UPLOADS_FOLDER,
  );

  return { secure_url, public_id };
};

const VideoPerview: React.FC<any> = (props: any) => {
  const { setCurrentTime, url } = props;

  const record = useRecordContext(props);
  const fileUrl = React.useMemo(
    () => url || window.URL.createObjectURL(record.rawFile),
    [url, record.rawFile],
  );
  const videoRef = useRef<HTMLVideoElement>();

  const onPlaying = () => {
    if (!!videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  return (
    <Grid display="flex" container direction="column">
      <video
        // @ts-ignore
        ref={videoRef}
        onTimeUpdate={onPlaying}
        src={fileUrl}
        controls
        height="100%"
        width="100%"
      />
    </Grid>
  );
};

export const EmailGifDialog: React.FC<any> = (props: any) => {
  const { isGifGenVisible, setIsGifGenVisible, externalSetter } = props;
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [videoUrl, setVideoUrl] = React.useState<string>('');
  const [generatedGifUrl, setGeneratedGifUrl] = React.useState<string>('');
  const [isLoadingUrl, setIsLoadingUrl] = React.useState<boolean>(false);
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);
  const [isGifLoaded, setIsGifLoaded] = React.useState<boolean>(false);
  const notify = useNotify();
  const { setValue } = useFormContext();

  return (
    <Dialog
      open={isGifGenVisible}
      onClose={() => {
        setIsGifGenVisible(false);
      }}
      aria-labelledby="form-dialog-title"
      className={commonStyles().gifDialog}
    >
      <DialogTitle id="form-dialog-title">Generate Gif</DialogTitle>
      <DialogContent>
        <Grid
          display="flex"
          container
          direction="row"
          justifyContent="space-around"
        >
          <Grid display="flex" container direction="column" width="40%">
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                const VideoUrlDisplay = (
                  <Grid display="flex" container direction="column">
                    <Grid
                      display="flex"
                      container
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Labeled label="Or set video URL">
                        <TextInput source="gifVideoUrl" label="URL" />
                      </Labeled>
                      <Button
                        label="Load Video"
                        disabled={
                          !formData.gifVideoUrl ||
                          formData.gifVideoUrl.length === 0
                        }
                        onClick={async () => {
                          setIsLoadingUrl(true);
                          setVideoUrl(formData.gifVideoUrl);
                          setIsLoadingUrl(false);
                        }}
                      >
                        {isLoadingUrl ? <CircularProgress /> : <Download />}
                      </Button>
                      {videoUrl && videoUrl.length > 0 ? (
                        <>
                          <VideoPerview
                            url={videoUrl}
                            currentTime={currentTime}
                            setCurrentTime={setCurrentTime}
                          />
                          <Button
                            label="Clear Video"
                            onClick={async () => {
                              formData.gifVideoUrl = '';
                              setVideoUrl('');
                            }}
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                );

                const VideoUploadDisplay = (
                  <ImageInput
                    source="gifVideoUpload"
                    label="Upload video"
                    accept="video/*,image/gif"
                    children={
                      <VideoPerview
                        currentTime={currentTime}
                        setCurrentTime={setCurrentTime}
                      />
                    }
                    className={commonStyles().removeButton}
                  />
                );

                if (videoUrl && videoUrl.length > 0) {
                  return VideoUrlDisplay;
                }

                if (formData.gifVideoUpload) {
                  if (formData.gifVideoUpload.rawFile.type.includes('video')) {
                    return VideoUploadDisplay;
                  } else {
                    return !generatedGifUrl ? (
                      <img
                        style={{ width: '500px' }}
                        src={URL.createObjectURL(
                          formData.gifVideoUpload.rawFile,
                        )}
                        alt="Gif preview"
                      />
                    ) : null;
                  }
                }
                return (
                  <>
                    {VideoUrlDisplay}
                    {VideoUploadDisplay}
                  </>
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid display="flex" container direction="column" width="40%">
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (formData && formData.gifVideoUrl) ||
                  (formData.gifVideoUpload &&
                    formData.gifVideoUpload.rawFile.type.includes('video')) ? (
                  <>
                    <NumberInput
                      source="gifSampling"
                      label="Sampling - Total frames"
                      defaultValue={40}
                    />
                    <BooleanInput source="gifLoop" label="Loop" />
                    <span>Aspect Ratio</span>
                    <NumberInput
                      source="gifScaleWidth"
                      label="Width"
                      defaultValue={420}
                    />
                    <NumberInput
                      source="gifScaleHeight"
                      label="Height"
                      defaultValue={720}
                    />
                    <Grid
                      display="flex"
                      container
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Grid
                        display="flex"
                        container
                        direction="column"
                        width="50%"
                      >
                        <Labeled label="Start time">
                          <TextField record={{ id: currentTime }} source="id" />
                        </Labeled>
                      </Grid>
                      <Grid
                        display="flex"
                        container
                        direction="column"
                        width="50%"
                      >
                        <TextInput
                          source="gifVideoDuration"
                          label="Duration (seconds)"
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null;
              }}
            </FormDataConsumer>
          </Grid>
          <Grid
            display="flex"
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            textAlign="center"
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Button
                  label="Load Gif"
                  disabled={
                    isGenerating ||
                    ((!videoUrl || videoUrl.length === 0) &&
                      (!formData || !formData.gifVideoUpload)) ||
                    (generatedGifUrl.length > 0 && !isGifLoaded)
                  }
                  onClick={async () => {
                    try {
                      if (
                        formData?.gifVideoUpload?.rawFile?.type?.includes(
                          'video',
                        )
                      ) {
                        setIsGifLoaded(false);
                        setIsGenerating(true);
                        setGeneratedGifUrl('');
                        const {
                          public_id: videoPublicId,
                          secure_url: fullUrl,
                        } = await uploadVideo(
                          formData?.gifVideoUpload?.rawFile,
                          videoUrl,
                        );

                        const genGifUrl = gifUrlBuilder(
                          fullUrl,
                          videoPublicId,
                          {
                            video_sampling: formData.gifSampling,
                            duration: {
                              start: currentTime,
                              ...(formData.gifVideoDuration
                                ? {
                                    end:
                                      currentTime + formData.gifVideoDuration,
                                  }
                                : {}),
                            },
                            scale: {
                              width: formData.gifScaleWidth,
                              height: formData.gifSacleHeight,
                            },
                            loop: formData.gifLoop,
                          },
                        );

                        setGeneratedGifUrl(genGifUrl);
                      } else if (
                        formData?.gifVideoUpload?.rawFile?.type?.includes(
                          'image',
                        )
                      ) {
                        setIsGifLoaded(false);
                        setIsGenerating(true);
                        setGeneratedGifUrl('');
                        const response = await uploadFile(
                          formData.gifVideoUpload.rawFile,
                          'email_gif',
                        );

                        const { json: data, status } = response;

                        if (status !== 201 || !data.url) {
                          throw new Error(
                            `Failed to upload asset to S3: ${JSON.stringify(
                              formData?.gifVideoUpload?.rawFile,
                            )}`,
                          );
                        }
                        setGeneratedGifUrl(data.url);
                      }
                    } catch (err) {
                      console.error(
                        `Failed to upload asset to S3: ${JSON.stringify(err)}`,
                      );
                      notify(`Failed to upload source video`, {
                        type: 'error',
                      });
                    } finally {
                      setIsGenerating(false);
                    }
                  }}
                />
              )}
            </FormDataConsumer>
            {isGenerating || (generatedGifUrl.length > 0 && !isGifLoaded) ? (
              <CircularProgress />
            ) : null}
            <Grid visibility={isGifLoaded ? 'visible' : 'hidden'}>
              <Labeled label="Preview">
                <img
                  alt="Loading..."
                  src={generatedGifUrl}
                  width="40%"
                  onLoad={() => setIsGifLoaded(true)}
                />
              </Labeled>
              <Button
                label="Clear"
                onClick={async () => {
                  setGeneratedGifUrl('');
                  setIsGifLoaded(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label="Cancel"
          onClick={() => {
            setValue('gifVideoUpload', null);
            setIsGifGenVisible(false);
          }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <Button
              label="Set Gif URL"
              disabled={
                isGenerating ||
                generatedGifUrl.length === 0 ||
                (generatedGifUrl.length > 0 && !isGifLoaded)
              }
              onClick={async () => {
                externalSetter(generatedGifUrl);
                setIsGifGenVisible(false);
              }}
            >
              {isGenerating || (generatedGifUrl.length > 0 && !isGifLoaded) ? (
                <CircularProgress />
              ) : (
                <Save />
              )}
            </Button>
          )}
        </FormDataConsumer>
      </DialogActions>
    </Dialog>
  );
};

const VariableInputs: React.FC<{
  getSource?: (src: string) => string;
  onGifUploadClick?: Function;
}> = ({ getSource = src => `variables.${src}`, onGifUploadClick }) => {
  return (
    <Grid container direction={'column'} display="flex">
      <TextInput source={getSource('c_email_title')} label="Email Title" />
      <TextInput source={getSource('c_email_text')} label="Email Text" />
      <TextInput source={getSource('c_email_sealer')} label="Email Sealer" />
      <TextInput source={getSource('c_email_CTA')} label="Email CTA" />
      <Grid container direction="row">
        <TextInput source={getSource('c_email_gif')} label="Email Gif URL" />
        {!!onGifUploadClick && (
          <Button onClick={() => onGifUploadClick()}>
            <AddPhotoAlternate />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export const EmailCRUDFormContent: React.FC = (props: any) => {
  const [isLegendVisible, setLegendVisibility] = React.useState<boolean>(false);
  const [isGifGenVisible, setIsGifGenVisible] = React.useState<boolean>(false);

  const { edit, template } = props;

  const { setValue } = useFormContext();
  return (
    <Grid
      container
      direction={'column'}
      display="flex"
      justifyContent={'space-around'}
    >
      <Grid
        container
        direction={'row'}
        display="flex"
        justifyContent={'space-around'}
      >
        <Grid container direction={'column'} display="flex" width={'40%'}>
          {GeneralInputs(edit, template)}
        </Grid>
        <Grid container direction={'column'} display="flex" width={'40%'}>
          <VariableInputs onGifUploadClick={() => setIsGifGenVisible(true)} />
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        direction={'column'}
        justifyContent={'space-around'}
      >
        {isLegendVisible ? (
          <Grid
            container
            display="flex"
            direction={'column'}
            justifyContent={'space-around'}
          >
            <Grid container display="flex" direction={'row'} wrap="wrap">
              <h3>Variables:</h3>
              <Button onClick={() => setLegendVisibility(false)} label="Hide" />
            </Grid>
            <h4>General Variables:</h4>
            <Grid container display="flex" direction={'row'} wrap="wrap">
              {VarsLegendItems(CastVariablesList)}
            </Grid>
            <h4>Email Variables:</h4>
            <Grid container display="flex" direction={'row'} wrap="wrap">
              {VarsLegendItems(emailVariables)}
            </Grid>
          </Grid>
        ) : (
          <Button
            onClick={() => setLegendVisibility(true)}
            label="Show Variables"
          />
        )}
      </Grid>
      {edit && (
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <Grid
              display="flex"
              width="100%"
              alignItems={'center'}
              marginBottom={'10px'}
            >
              {getMissingVariablesByType(formData.type, formData.content)
                .length > 0 && (
                <>
                  <Typography variant="h6" mr="10px" color="red">
                    Missing variables:
                  </Typography>
                  <div style={{ width: '80%' }}>
                    {getMissingVariablesByType(
                      formData.type,
                      formData.content,
                    ).join(', ')}
                  </div>
                </>
              )}
            </Grid>
          )}
        </FormDataConsumer>
      )}
      <Grid display="flex" width="100%" justifyContent={'space-between'}>
        <TextInput
          multiline
          source="content"
          label="Email Content"
          isRequired
          validate={[required()]}
          fullWidth
          style={{ width: '49%' }}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div
              dangerouslySetInnerHTML={{
                __html: edit ? formData.preview : formData.content,
              }}
              style={{ border: '1px solid black', width: '49%' }}
            />
          )}
        </FormDataConsumer>
      </Grid>
      <EmailGifDialog
        isGifGenVisible={isGifGenVisible}
        setIsGifGenVisible={setIsGifGenVisible}
        externalSetter={(url: string) =>
          setValue('variables.c_email_gif', url, { shouldDirty: true })
        }
      />
    </Grid>
  );
};

export const EmailTranslations = () => {
  const campaignId = useWatch({ name: 'campaignId' });

  if (!!campaignId)
    return <div>Translations is only availables on email templates</div>;

  return (
    <Grid container spacing={2}>
      {SupportedLocalesChoices.map(lang => (
        <Grid item key={lang.shortId}>
          <Card style={{ padding: '10px' }}>
            <Typography variant="h6">{lang.shortEngName}</Typography>
            <TextInput
              source={
                lang.shortId === 'en'
                  ? `subject`
                  : `translations.${lang.shortId}.subject`
              }
              label="Subject"
            />
            <VariableInputs
              getSource={src =>
                lang.shortId === 'en'
                  ? `variables.${src}`
                  : `translations.${lang.shortId}.${src}`
              }
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const EmailEditActions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onExport = async (emailProvider: string) => {
    try {
      const res = await exportEmail(record.id.toString(), emailProvider);
      downloadBlob(new Blob([res.json.html]), `email-${record.id}.html`);
      handleClose();
    } catch (err) {
      console.error(err);
      notify('Failed to export email', { type: 'error' });
    }
  };
  return (
    <TopToolbar>
      <Button
        variant="contained"
        onClick={handleClick}
        label="Export"
        endIcon={<KeyboardArrowDown />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => onExport('CAST_EMAIL')}>Cast</MenuItem>
      </Menu>
    </TopToolbar>
  );
};

export const EmailEdit: React.FC = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = ({ type, content }: any) => {
    if (!isMissingVariablesByEmailType(type, content)) {
      notify('Email updated successfully', { type: 'success' });
    } else {
      notify(
        `Updated successfully with missing variables:\n${getMissingVariablesByType(
          type,
          content,
        ).join(' - ')}`,
        { type: 'info' },
      );
    }
    refresh();
  };

  return (
    <Edit
      redirect={false}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      className={stickyToolbar().root}
      actions={<EmailEditActions />}
      transform={(data: any) => {
        return {
          ...data,
          campaignTemplateId:
            data.campaignTemplateId === '' ? null : data.campaignTemplateId,
          replyTo: data.replyTo === '' ? null : data.replyTo,
        };
      }}
      {...props}
    >
      <>
        <TabbedForm>
          <FormTab label="summary">
            <EmailCRUDFormContent edit {...props} />
          </FormTab>
          <FormTab label="translations">
            <EmailTranslations />
          </FormTab>
        </TabbedForm>
      </>
    </Edit>
  );
};

export const EmailCreate: React.FC = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const query = useQuery();
  const campaignTemplateIdStr = query.get('campaignTemplateId');
  const campaignId = query.get('campaignId');

  // @ts-ignore
  const campaignTemplateId = campaignTemplateIdStr
    ? parseInt(campaignTemplateIdStr, 10)
    : null;

  const onSuccess = () => {
    notify(`Email Created!`, { type: 'success' });
    if (campaignTemplateId) redirect(`/templates/${campaignTemplateId}/2`);
    else if (campaignId) redirect(`/campaigns/${campaignId}/4`);
  };

  const [openCreateFromDialog, setOpenCreateFromDialog] = React.useState(false);

  const onSubmitCopyForm = async (data: any) => {
    try {
      const email = await copyEmail({
        ...data,
        campaignTemplateId,
        campaignId,
      });
      if (!email.id) throw new Error();

      notify('Email created successfully', { type: 'success' });
      setOpenCreateFromDialog(false);
      redirect(`/email/${email.id}`);
    } catch (error) {
      notify('Failed to create email. Check from and try again.', {
        type: 'error',
      });
    }
  };

  return (
    <Create
      mutationOptions={{ onSuccess }}
      transform={(data: any) => ({
        ...data,
        campaignTemplateId:
          data.campaignTemplateId === '' ? null : data.campaignTemplateId,
        campaignId: data.campaignId === '' ? null : data.campaignId,
        replyTo: data.replyTo === '' ? null : data.replyTo,
      })}
      {...props}
    >
      <SimpleForm defaultValues={{ campaignTemplateId, campaignId }}>
        <Button
          onClick={() => setOpenCreateFromDialog(true)}
          variant="outlined"
          size="large"
          label="Create email from"
          style={{ marginBottom: '10px' }}
        />
        <EmailCRUDFormContent {...props} />
      </SimpleForm>
      <CreateEmailFromDialog
        open={openCreateFromDialog}
        onSubmit={onSubmitCopyForm}
        onCancel={() => setOpenCreateFromDialog(false)}
      />
    </Create>
  );
};
